import axios from "axios";
import moment from "moment";
import AffiliateTable from "./AffiliateTable";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  TablePagination,
  Dialog,
} from "@material-ui/core";
import { NavigateNext, NavigateBefore, Clear } from "@material-ui/icons";
import { URL } from "../../config/config";
import AddIcon from "@material-ui/icons/AddOutlined";
import AddAffiliate from "./AddAffiliate";

const createInitialState = () => ({
  rowsPerPage: 15,
  totalCount: null,
  skip: 0,
  timeStamp: new Date(),
  search: "",
  /** @type {Types.Partner[]} */
  partners: [],
  err: null,
  isLoading: false,
  page: 0,
  applied: {
    createdAt: {
      startDate: null,
      endDate: null,
      focus: null,
    },
  },
  openAddPartnerDialog: false,
});

/**
 * @typedef {WithStyles<typeof styles>} Props
 * @typedef {ReturnType<typeof createInitialState>} State
 */

/**
 * @extends Component<Props, State>
 */
class AffiliatePage extends Component {
  constructor(props) {
    super(props);
    this.state = createInitialState();
  }

  componentDidMount() {
    this.fetchPartners();
  }

  closeAddPartnerDialog = () => this.setState({ openAddPartnerDialog: false });

  onAddedPartnerDialog = () => {
    this.closeAddPartnerDialog();
    this.setState(createInitialState(), () => {
      this.fetchPartners();
    });
  };

  openAddPartnerDialog = () => this.setState({ openAddPartnerDialog: true });

  fetchPartners = async () => {
    this.setState({ isLoading: true, err: null });
    try {
      const t = this.state;
      let filter = {};
      if (t.search) {
        filter["search"] = t.search;
      }
      if (t.applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: t.applied.createdAt.startDate,
          $lte: t.applied.createdAt.endDate,
        };
      }

      const queryObj = {
        skip: t.skip,
        timeStamp: t.timeStamp,
        rowsPerPage: t.rowsPerPage,
        search: "",
        allFilters: { ...filter },
      };

      const response = await axios.get(`${URL}/superadmin/get-affiliates`, {
        params: queryObj,
      });
      const { totalCount, newSkip, affiliates } = response.data;
      this.setState({
        skip: newSkip,
        partners: [...this.state.partners, ...affiliates],
        totalCount,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ err, isLoading: false });
    }
  };

  /** @param {Partner} partner */
  setPartner = (partner) => {
    const partners = [...this.state.partners];
    const foundIndex = partners.findIndex((x) => x._id == partner._id);
    partners[foundIndex] = partner;
    this.setState({
      partners,
    });
  };

  getPageRows = () => {
    const { partners, page, rowsPerPage } = this.state;
    return partners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  handleChangePage = (event, newPage) => {
    const { partners, page, rowsPerPage } = this.state;
    const step = newPage - page;
    if (step === 1) {
      const pageRows = partners.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );
      if (!pageRows.length) {
        this.fetchPartners();
      }
    }
    this.setState({ page: newPage });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key == "Enter") {
      this.setState(
        {
          timeStamp: new Date(),
          skip: 0,
          page: 0,
          totalCount: null,
          partners: [],
          isLoading: true,
          err: null,
        },
        () => {
          this.fetchPartners();
        }
      );
    }
  };
  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        partners: [],
        isLoading: true,
        err: null,
      },
      () => {
        this.fetchPartners();
      }
    );
  };

  render() {
    const { classes, agent } = this.props;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3">Affiliates</Typography>
          </Grid>
          <Grid item>
            <TextField
              name="search"
              placeholder="Search Affiliate"
              className={classes.textField}
              onChange={this.handleInput}
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <DateFilter
              filterKey="createdAt"
              filterName="Created At"
              classes={classes}
              applied={this.state.applied}
              setDate={this.setDate}
              setFocus={this.setFocus}
              checkDateInput={this.checkDateInput}
              clearDate={this.clearDate}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={this.applyFilter}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            {agent.power <= 10 ? (
              <></>
            ) : (
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={this.openAddPartnerDialog}
              >
                Add Affiliate
              </Button>
            )}
          </Grid>
        </Grid>
        <Box my={3} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <AffiliateTable
                partners={this.getPageRows()}
                isLoading={this.state.isLoading}
                setPartner={this.setPartner}
              />
            </div>
          </Grid>
        </Grid>
        {/* Table footer fixed at bottom */}
        <div className={classes.fixedBottomContainer}>
          <TablePagination
            component="div"
            count={this.state.totalCount || 0}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={[25]}
          />
        </div>
        <Dialog open={this.state.openAddPartnerDialog}>
          <AddAffiliate
            onClose={this.closeAddPartnerDialog}
            onSuccess={this.onAddedPartnerDialog}
          />
        </Dialog>
      </div>
    );
  }
}

function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box className={classes.filterTypeContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {A.startDate && A.endDate ? (
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Typography variant="body1">{filterName}</Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

/** @param {Theme} theme */
const styles = (theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      background: "rgb(249,249,249)",
      boxSizing: "border-box",
      overflowX: "hidden",
      position: "relative",
    },
    pageTitleContainer: {
      position: "sticky",
      zIndex: 100,
      top: 0,
      height: 60,
      boxSizing: "border-box",
      alignItems: "center",
      padding: "0 60px",
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    fullWidth: {
      background: "white",
    },
    container: {
      background: "white",
      borderRadius: "8px",
    },
    fixedBottomContainer: {
      position: "fixed",
      height: "60px",
      borderTop: "1px solid lightgrey",
      background: "white",
      bottom: "0",
      left: "71px",
      right: "0",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        height: "50px",
      },
    },
    tableContainer: {
      top: "60px",
      height: "calc(100vh - 70px - 60px - 40px)",
      width: "100%",
      overflow: "hidden",
      boxSizing: "border-box",
      position: "sticky",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 70px - 50px - 10px - 50px)",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxSizing: "border-box",
      },
    },
    textField: {
      width: 300,
    },
  });

const connectedPartnersPage = connect((state) => ({
  agent: state.login.user,
}))(AffiliatePage);

export default withStyles(styles)(connectedPartnersPage);
