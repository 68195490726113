import React, { useState, useRef, useEffect } from "react";
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core";
import ApplyBvbp from "./ApplyBvbp";
import CheckStatus from "./CheckStatus";
import RejectKyc from "./RejectKyc";
import axios from "axios";
import { URL } from "../../config/config";

const messagingLimit = {
  TIER_250: 250,
  TIER_1K: 1000,
  TIER_10K: 10000,
  TIER_100K: 100000,
  TIER_UNLIMITED: 1000000,
};

const BvbpDialog = (props) => {
  const {
    value,
    toggleDialog,
    open,
    AssistantsDetails,
    alertHandler,
    setTemplateData,
  } = props;
  const [rejectionDialog, setRejectionDialog] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [reApply, SetReApply] = useState(false);
  const [website, setWebsite] = useState("");

  useEffect(() => {
    axios
      .post(URL + "/superadmin/get-client-details", {
        assistantId: AssistantsDetails._id,
      })
      .then((response) => {
        setWebsite(response?.data?.website || "");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function BvbpApiCall(data) {
    axios
      .post(
        `${URL}/superadmin/bvbp-apply?assistantId=${AssistantsDetails._id}`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          alertHandler(true, "Applied successfully!", "success");
          setTemplateData(AssistantsDetails?._id);
        }
        toggleDialog();
      })
      .catch((error) => {
        console.error("Error:", error);
        alertHandler(true, "Something went wrong", "error");
        toggleDialog();
      });
  }

  function parseAddress(gstInfo) {
    const address = gstInfo.principalAddress.address;

    // Split the address string into parts using a comma as a separator
    const addressParts = address.split(",").map((part) => part.trim());

    // Extract the last three parts
    const postalCode = addressParts.pop();
    const stateProvinceOrRegion = addressParts.pop();
    const cityOrTown = addressParts.pop();

    // Combine the remaining parts into a single string
    const remainingAddress = addressParts.join(", ");

    // Split the remaining address into two equal parts without breaking words
    const midpoint = Math.floor(remainingAddress.length / 2);
    let splitPoint = remainingAddress.lastIndexOf(" ", midpoint);

    if (splitPoint === -1) {
      splitPoint = midpoint; // In case there's no space to split, use the midpoint
    }

    const streetAddress1 = remainingAddress.slice(0, splitPoint).trim();
    const streetAddress2 = remainingAddress.slice(splitPoint).trim();

    // Return the formatted address object
    return {
      street_address_1: streetAddress1,
      street_address_2: streetAddress2,
      city_or_town: cityOrTown,
      state_province_or_region: stateProvinceOrRegion,
      postal_code: postalCode,
    };
  }

  const onRejectionApicall = (e, data, id) => {
    axios
      .post(URL + "/superadmin/reject-kyc", {
        reason: data,
        assistantId: id,
      })
      .then((response) => {
        alertHandler(true, "KYC rejected successfully!", "success");
        setTemplateData(AssistantsDetails?._id);
        setRejectionDialog(!rejectionDialog);
        toggleDialog();
      })
      .catch((err) => {
        console.error(err);
        alertHandler(true, "Something went wrong", "error");
        setRejectionDialog(!rejectionDialog);
        toggleDialog();
      });
  };

  const syncMessagingApiCall = (numberId) => {
    if (numberId) {
      const payload = {
        wabaNumberId: numberId,
      };
      axios
        .get(URL + "/superadmin/sync-messaging-limit", { params: payload })
        .then((response) => {
          alertHandler(true, "Messaging limit updated", "success");
          setTemplateData(
            AssistantsDetails?._id,
            "dailyTemplateLimit",
            messagingLimit[response.data.messaging_limit_tier]
          );
        })
        .catch((error) => {
          console.log(error);
          alertHandler(true, "Failed", "error");
        });
    } else {
      alertHandler(true, "Waba Number Id missing", "error");
    }
  };

  return (
    <>
      {value == 0 ? (
        <ApplyBvbp
          toggleBvbpDialog={toggleDialog}
          bvbpDialog={open}
          onBvbpSave={BvbpApiCall}
          defaultData={{
            end_business_id: `${AssistantsDetails?.fbBusinessId || ""}`,
            assistantId: AssistantsDetails?._id || "",
            end_business_legal_name: "",
            end_business_website: `${
              AssistantsDetails?.whatsAppBusinessProfile?.websites?.[0] ||
              website
            }`,
            num_billing_cycles_with_partner: 1,
            end_business_address: {
              ...parseAddress(AssistantsDetails?.gstInfo),
              country_code: "IN",
            },
            average_monthly_revenue_spend_with_partner: {
              amount: "1000",
              currency_code: "INR",
            },
          }}
          aadhaarGstInfo={{
            aadhaarInfo: AssistantsDetails?.aadhaarInfo,
            gstInfo: AssistantsDetails?.gstInfo,
          }}
          rejectionDialog={rejectionDialog}
          setRejectionDialog={setRejectionDialog}
          rejectionReason={rejectionReason}
          setRejectionReason={setRejectionReason}
          onRejectHandler={(e) =>
            onRejectionApicall(e, rejectionReason, AssistantsDetails?._id)
          }
        />
      ) : (
        <>
          {" "}
          {reApply ? (
            <>
              <ApplyBvbp
                toggleBvbpDialog={toggleDialog}
                bvbpDialog={open}
                onBvbpSave={BvbpApiCall}
                defaultData={{
                  end_business_id: `${AssistantsDetails?.fbBusinessId || ""}`,
                  assistantId: AssistantsDetails?._id || "",
                  end_business_legal_name: "",
                  end_business_website: `${
                    AssistantsDetails?.whatsAppBusinessProfile?.websites?.[0] ||
                    website
                  }`,
                  num_billing_cycles_with_partner: 1,
                  end_business_address: {
                    ...parseAddress(AssistantsDetails?.gstInfo),
                    country_code: "IN",
                  },
                  average_monthly_revenue_spend_with_partner: {
                    amount: "1000",
                    currency_code: "INR",
                  },
                }}
                aadhaarGstInfo={{
                  aadhaarInfo: AssistantsDetails?.aadhaarInfo,
                  gstInfo: AssistantsDetails?.gstInfo,
                }}
                rejectionDialog={rejectionDialog}
                setRejectionDialog={setRejectionDialog}
                rejectionReason={rejectionReason}
                setRejectionReason={setRejectionReason}
                onRejectHandler={(e) =>
                  onRejectionApicall(e, rejectionReason, AssistantsDetails?._id)
                }
              />
            </>
          ) : (
            <>
              <>
                <RejectKyc
                  rejectionDialog={rejectionDialog}
                  setRejectionDialog={setRejectionDialog}
                  rejectionReason={rejectionReason}
                  setRejectionReason={setRejectionReason}
                  onRejectHandler={(e) =>
                    onRejectionApicall(
                      e,
                      rejectionReason,
                      AssistantsDetails?._id
                    )
                  }
                />
              </>
              <DialogTitle id="simple-dialog-title" style={{ padding: "24px" }}>
                <Typography variant="h3">{"Check Status"}</Typography>
              </DialogTitle>
              <DialogContent>
                <CheckStatus
                  businessId={AssistantsDetails?.fbBusinessId}
                  dailyTemplateLimit={AssistantsDetails?.dailyTemplateLimit}
                  alertHandler={alertHandler}
                  syncMessagingLimit={(e) =>
                    syncMessagingApiCall(AssistantsDetails?.wabaNumberId)
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => SetReApply(!reApply)}
                >
                  Re-Apply
                </Button>
                <Button
                  onClick={toggleDialog}
                  color="primary"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setRejectionDialog(!rejectionDialog)}
                  variant="contained"
                  color="primary"
                >
                  Reject KYC
                </Button>
              </DialogActions>
            </>
          )}
        </>
      )}
    </>
  );
};

export default BvbpDialog;
