import React, { useState, useRef, useEffect } from "react";
import {
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  withStyles,
  Icon,
  IconButton,
  Box,
} from "@material-ui/core";

import { URL } from "../../config/config";
import { Clear, Publish } from "@material-ui/icons";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";
import RejectKyc from "./RejectKyc";

const currencyCode = [
  "AD",
  "AE",
  "AF",
  "AG",
  "AI",
  "AL",
  "AM",
  "AN",
  "AO",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AW",
  "AX",
  "AZ",
  "BA",
  "BB",
  "BD",
  "BE",
  "BF",
  "BG",
  "BH",
  "BI",
  "BJ",
  "BL",
  "BM",
  "BN",
  "BO",
  "BQ",
  "BR",
  "BS",
  "BT",
  "BV",
  "BW",
  "BY",
  "BZ",
  "CA",
  "CC",
  "CD",
  "CF",
  "CG",
  "CH",
  "CI",
  "CK",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CU",
  "CV",
  "CW",
  "CX",
  "CY",
  "CZ",
  "DE",
  "DJ",
  "DK",
  "DM",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "EH",
  "ER",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FK",
  "FM",
  "FO",
  "FR",
  "GA",
  "GB",
  "GD",
  "GE",
  "GF",
  "GG",
  "GH",
  "GI",
  "GL",
  "GM",
  "GN",
  "GP",
  "GQ",
  "GR",
  "GS",
  "GT",
  "GU",
  "GW",
  "GY",
  "HK",
  "HM",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IM",
  "IN",
  "IO",
  "IQ",
  "IR",
  "IS",
  "IT",
  "JE",
  "JM",
  "JO",
  "JP",
  "KE",
  "KG",
  "KH",
  "KI",
  "KM",
  "KN",
  "KP",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LC",
  "LI",
  "LK",
  "LR",
  "LS",
  "LT",
  "LU",
  "LV",
  "LY",
  "MA",
  "MC",
  "MD",
  "ME",
  "MF",
  "MG",
  "MH",
  "MK",
  "ML",
  "MM",
  "MN",
  "MO",
  "MP",
  "MQ",
  "MR",
  "MS",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NC",
  "NE",
  "NF",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NR",
  "NU",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PF",
  "PG",
  "PH",
  "PK",
  "PL",
  "PM",
  "PN",
  "PR",
  "PS",
  "PT",
  "PW",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "RU",
  "RW",
  "SA",
  "SB",
  "SC",
  "SD",
  "SE",
  "SG",
  "SH",
  "SI",
  "SJ",
  "SK",
  "SL",
  "SM",
  "SN",
  "SO",
  "SR",
  "SS",
  "ST",
  "SV",
  "SX",
  "SY",
  "SZ",
  "TC",
  "TD",
  "TF",
  "TG",
  "TH",
  "TJ",
  "TK",
  "TL",
  "TM",
  "TN",
  "TO",
  "TR",
  "TT",
  "TV",
  "TW",
  "TZ",
  "UA",
  "UG",
  "UM",
  "US",
  "UY",
  "UZ",
  "VA",
  "VC",
  "VE",
  "VG",
  "VI",
  "VN",
  "VU",
  "WF",
  "WS",
  "XK",
  "YE",
  "YT",
  "ZA",
  "ZM",
  "ZW",
];

const countryCode = [
  "AFN",
  "ALL",
  "DZD",
  "AOA",
  "ARS",
  "AMD",
  "AWG",
  "AUD",
  "AZN",
  "BSD",
  "BHD",
  "BDT",
  "BBD",
  "BYN",
  "BZD",
  "BMD",
  "BTN",
  "BOB",
  "BAM",
  "BWP",
  "BRL",
  "GBP",
  "BND",
  "BGN",
  "MMK",
  "BIF",
  "KHR",
  "CAD",
  "CVE",
  "KYD",
  "XAF",
  "XPF",
  "CLP",
  "CLF",
  "CNY",
  "COP",
  "KMF",
  "CDF",
  "CRC",
  "HRK",
  "CZK",
  "DKK",
  "DJF",
  "DOP",
  "XCD",
  "EGP",
  "ERN",
  "EEK",
  "ETB",
  "EUR",
  "FKP",
  "FJD",
  "GMD",
  "GEL",
  "GHS",
  "GIP",
  "GTQ",
  "GNF",
  "GYD",
  "HTG",
  "HNL",
  "HKD",
  "HUF",
  "ISK",
  "INR",
  "IDR",
  "IQD",
  "ILS",
  "JMD",
  "JPY",
  "JOD",
  "KZT",
  "KES",
  "KRW",
  "KWD",
  "KGS",
  "LAK",
  "LVL",
  "LBP",
  "LSL",
  "LRD",
  "LYD",
  "LTL",
  "MOP",
  "MKD",
  "MGA",
  "MWK",
  "MYR",
  "MVR",
  "MRO",
  "MUR",
  "MXN",
  "MDL",
  "MNT",
  "MAD",
  "MZN",
  "NAD",
  "NPR",
  "ANG",
  "NZD",
  "NIO",
  "NGN",
  "NOK",
  "OMR",
  "PKR",
  "PAB",
  "PGK",
  "PYG",
  "PEN",
  "PHP",
  "PLN",
  "QAR",
  "RON",
  "RUB",
  "RWF",
  "SHP",
  "SVC",
  "WST",
  "STD",
  "SAR",
  "RSD",
  "SCR",
  "SLE",
  "SLL",
  "SGD",
  "SKK",
  "SBD",
  "SOS",
  "ZAR",
  "SSP",
  "LKR",
  "SRD",
  "SZL",
  "SEK",
  "CHF",
  "TWD",
  "TJS",
  "TZS",
  "THB",
  "TOP",
  "TTD",
  "TND",
  "TRY",
  "TMT",
  "AED",
  "UGX",
  "UAH",
  "USD",
  "UYU",
  "UZS",
  "VUV",
  "VEF",
  "VES",
  "VND",
  "XOF",
  "YER",
  "ZMW",
  "ZWL",
  "FBZ",
];
const TotalFields = [
  {
    id: "end_business_id",
    label: "Business Id",
    required: true,
  },
  {
    id: "end_business_legal_name",
    label: "Legal Name",
    required: true,
  },
  {
    id: "end_business_website",
    label: "Website",
    required: true,
  },
  {
    id: "num_billing_cycles_with_partner",
    label: "Billing Cycles",
    type: "number",
  },
  {
    id: "street_address_1",
    label: "street address 1",
    group: "end_business_address",
    required: true,
  },
  {
    id: "street_address_2",
    label: "street address 2",
    group: "end_business_address",
  },
  {
    id: "city_or_town",
    label: "City",
    group: "end_business_address",
    required: true,
  },
  {
    id: "state_province_or_region",
    label: "State province",
    group: "end_business_address",
    required: true,
  },
  {
    id: "postal_code",
    label: "Postal code",
    group: "end_business_address",
    required: true,
  },
  {
    id: "country_code",
    label: "Country code",
    group: "end_business_address",
    Autocomplete: true,
    required: true,
  },
  {
    id: "amount",
    label: "Amount",
    group: "average_monthly_revenue_spend_with_partner",
    required: true,
    type: "number",
  },
  {
    id: "currency_code",
    label: "Currency code",
    Autocomplete: true,
    group: "average_monthly_revenue_spend_with_partner",
    required: true,
  },
];

const ApplyBvbp = (props) => {
  const fileInputRef = useRef(null);
  const {
    toggleBvbpDialog,
    bvbpDialog,
    classes,
    onBvbpSave,
    defaultData,
    aadhaarGstInfo,
    rejectionDialog,
    setRejectionDialog,
    rejectionReason,
    setRejectionReason,
    onRejectHandler,
  } = props;
  const [data, setData] = useState(defaultData);
  const [files, setFiles] = useState([]);
  const [required, setRequired] = useState();
  const [FileUrlObject, setFileUrlObject] = useState({});
  const [fBMInfo, setFBMInfo] = useState({});

  const onchangeHandler = (e, id, group) => {
    e.persist();
    if (group) {
      setData((prevState) => ({
        ...prevState,
        [group]: {
          ...prevState[group],
          [id]: e.target.value,
        },
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        [id]: e.target.value,
      }));
    }
  };

  const onCancelHandler = () => {
    setData({});
    setFiles([]);
    toggleBvbpDialog();
  };
  const removeFileHandler = (fileName) => {
    const tempFileArray = files.filter((file) => file.name != fileName);
    setFiles(tempFileArray);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    let validated = true;
    TotalFields.forEach((element, index) => {
      if (element.required) {
        if (element.group) {
          validated =
            validated && data[element.group]
              ? data[element.group][element.id]
                ? data[element.group][element.id].trim() != ""
                : false
              : false;
        } else {
          validated =
            validated &&
            (data[element.id] ? data[element.id].trim() != "" : false);
        }
      }
    });
    validated = validated && files.length <= 3 && files.length != 0;
    setRequired(validated);
  }, [data, files]);

  const handleFileChange = (event) => {
    event.persist();
    if (event.target?.files?.length > 0) {
      const selectedFiles = event.target.files[0];
      setFiles((prevFiles) => [...prevFiles, selectedFiles]);
    }
  };

  const uploadFileFromURL = async (url, name) => {
    try {
      // Fetch the image from the URL
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a File object from the Blob
      const fileName = url.split("/").pop(); // Extracting file name from URL
      const file = new File([blob], fileName, { type: blob.type });

      // Create a DataTransfer object and add the File object to it
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      // Trigger the change event manually
      const event = new Event("change", { bubbles: true });
      Object.defineProperty(event, "target", {
        value: { files: dataTransfer.files },
        writable: false,
      });

      if (event.target?.files?.length > 0) {
        const originalFile = event.target?.files[0];
        const renamedFile = new File([originalFile], name, {
          type: originalFile.type,
        });
        setFileUrlObject((prevUrls) => ({ ...prevUrls, [name]: url }));
        setFiles((prevFiles) => [...prevFiles, renamedFile]);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  useEffect(() => {
    uploadFileFromURL(
      aadhaarGstInfo?.gstInfo?.gstUrlInfo?.fullUrl,
      aadhaarGstInfo?.gstInfo?.gstUrlInfo?.name
    );
    uploadFileFromURL(
      aadhaarGstInfo?.aadhaarInfo?.aadhaarUrlInfo?.fullUrl,
      aadhaarGstInfo?.aadhaarInfo?.aadhaarUrlInfo?.name
    );
    if (defaultData?.end_business_id) {
      fetchFBMInfo();
    }
  }, []);

  const onSaveHandler = () => {
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`business_documents[]`, files[i]);
    }
    const tempData = { ...data };
    tempData.num_billing_cycles_with_partner =
      parseInt(tempData.num_billing_cycles_with_partner) || 2;

    tempData.average_monthly_revenue_spend_with_partner.amount = parseInt(
      tempData.average_monthly_revenue_spend_with_partner.amount
    );
    formData.append("end_business_id", tempData.end_business_id);
    formData.append(
      "end_business_legal_name",
      tempData.end_business_legal_name
    );
    formData.append("end_business_website", tempData.end_business_website);
    formData.append(
      "end_business_address",
      JSON.stringify(tempData.end_business_address)
    );
    formData.append(
      "average_monthly_revenue_spend_with_partner",
      JSON.stringify(tempData.average_monthly_revenue_spend_with_partner)
    );
    formData.append(
      "num_billing_cycles_with_partner",
      tempData.num_billing_cycles_with_partner
    );

    onBvbpSave(formData);
  };

  const fetchFBMInfo = () => {
    const payload = {
      fbBusinessId: defaultData?.end_business_id,
    };
    axios
      .get(`${URL}/superadmin/fetch-fbm-meta-info`, {
        params: payload,
      })
      .then((res) => {
        setFBMInfo({
          name: res?.data?.businessInfo?.name,
          status: res?.data?.businessInfo?.verification_status,
        });
      })
      .catch((e) => {
        console.error(e);
        setFBMInfo({});
      });
  };

  return (
    <>
      <Dialog
        onClose={toggleBvbpDialog}
        open={bvbpDialog}
        // closeDialog={toggleBvbpDialog}
        maxWidth={"md"}
        fullWidth
      >
        <DialogTitle id="simple-dialog-title" style={{ padding: "24px" }}>
          <Typography variant="h3">Apply for BVBP</Typography>
        </DialogTitle>
        <DialogContent>
          {aadhaarGstInfo?.gstInfo && (
            <>
              <Grid
                container
                style={{
                  width: "100%",
                  border: "1px solid #b0b0b0",
                  margin: "10px 0px",
                  padding: "16px",
                  background: "#e9e9e9",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Grid item>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      marginBottom: "10px",
                      fontSize: "14px",
                    }}
                  >
                    {`G.S.T. Info`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    fontSize: "12px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    Directors :
                  </Typography>
                  {aadhaarGstInfo?.gstInfo?.directors.join(",")}
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    fontSize: "12px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    Legal Name :
                  </Typography>
                  {aadhaarGstInfo?.gstInfo?.legalName}
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    fontSize: "12px",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: "600",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                  >
                    Trade Name :
                  </Typography>
                  {aadhaarGstInfo?.gstInfo?.tradeName}
                </Grid>
              </Grid>
            </>
          )}

          {aadhaarGstInfo?.aadhaarInfo?.userName && (
            <Grid
              container
              style={{
                width: "100%",
                border: "1px solid #b0b0b0",
                margin: "10px 0px",
                padding: "16px",
                background: "#e9e9e9",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  {`Aadhaar Info`}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    marginRight: "10px",
                    fontSize: "12px",
                  }}
                >
                  Name :
                </Typography>
                {aadhaarGstInfo?.aadhaarInfo?.userName || ""}
              </Grid>
            </Grid>
          )}

          {fBMInfo?.name && (
            <Grid
              container
              style={{
                width: "100%",
                border: "1px solid #b0b0b0",
                margin: "10px 0px",
                padding: "16px",
                background: "#e9e9e9",
                borderRadius: "8px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    marginBottom: "10px",
                    fontSize: "14px",
                  }}
                >
                  {`FBM Info`}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    marginRight: "10px",
                    fontSize: "12px",
                  }}
                >
                  Name :
                </Typography>
                {fBMInfo?.name || ""}
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  style={{
                    fontWeight: "600",
                    marginRight: "10px",
                    fontSize: "12px",
                  }}
                >
                  Status :
                </Typography>
                {fBMInfo?.status || ""}
              </Grid>
            </Grid>
          )}

          <Grid item xs={12} md={12} container>
            {TotalFields.map((field) => (
              <>
                <Grid
                  container
                  md={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <Grid item xs={4} md={2} container alignItems="center">
                    <Typography required variant="h5">
                      {field.label}
                      {field.required && (
                        <span style={{ color: "red", marginLeft: "1px" }}>
                          *
                        </span>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    xs={8}
                    md={8}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      md={12}
                      style={{ display: "flex", width: "100%" }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={12}
                        style={{ marginRight: 4, width: "100%" }}
                      >
                        {field.Autocomplete ? (
                          <Autocomplete
                            options={
                              field.id == "country_code"
                                ? currencyCode.map((option) => option)
                                : countryCode.map((option) => option)
                            }
                            onChange={(e, value, reason) => {
                              if (field.group) {
                                setData((prevState) => ({
                                  ...prevState,
                                  [field.group]: {
                                    ...prevState[field.group],
                                    [field.id]: value,
                                  },
                                }));
                              } else {
                                setData((prevState) => ({
                                  ...prevState,
                                  [field.id]: value,
                                }));
                              }
                            }}
                            value={
                              (field.group
                                ? data[field.group]
                                  ? data[field.group][field.id]
                                  : ""
                                : data[field.id]) || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                className={classes.textFieldRoot}
                                InputProps={{ ...params.InputProps }}
                                style={{
                                  border: "1px solid ",
                                  background: "white",
                                }}
                              />
                            )}
                          />
                        ) : (
                          <TextField
                            style={{ width: "100%", padding: "3px 0px" }}
                            required
                            type={`${
                              field.type
                                ? field.type == "number"
                                  ? "number"
                                  : "string"
                                : "string"
                            }`}
                            variant="outlined"
                            id={`${field.id}`}
                            classes={{
                              root: classes.textFieldRoot,
                            }}
                            className={classes.textFieldRoot}
                            margin="dense"
                            onChange={(e) =>
                              onchangeHandler(e, field.id, field?.group)
                            }
                            value={
                              (field.group
                                ? data[field.group]
                                  ? data[field.group][field.id]
                                  : ""
                                : data[field.id]) || ""
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ))}
            <Grid
              container
              md={6}
              xs={12}
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Grid item xs={4} md={2} container alignItems="center">
                <Typography required variant="h5">
                  Documents
                  <span style={{ color: "red", marginLeft: "1px" }}>*</span>
                </Typography>
              </Grid>
              <Grid
                xs={8}
                md={8}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Grid item md={12} style={{ display: "flex", width: "100%" }}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{ marginRight: 4, width: "100%" }}
                  >
                    <div style={{ width: "100%", padding: "3px 0px" }}>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".pdf,.jpeg,.jpg,.png"
                        onChange={handleFileChange}
                      />
                    </div>
                    <Box onClick={handleClick}>
                      <Grid
                        item
                        md={12}
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "space-between",
                          cursor: "pointer",
                        }}
                        classes={{
                          root: classes.textFieldRoot,
                        }}
                      >
                        <Typography required variant="h5">
                          Upload File
                        </Typography>
                        <IconButton
                          color="primary"
                          size="small"
                          variant="filled"
                        >
                          <Publish />
                        </IconButton>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {files.length > 0 &&
              files.map((file) => (
                <FileDisplay
                  classes={classes}
                  name={file.name}
                  url={FileUrlObject[file.name]}
                  removeFile={removeFileHandler}
                ></FileDisplay>
              ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          {defaultData?.average_monthly_revenue_spend_with_partner?.amount && (
            <Button
              onClick={() => setRejectionDialog(!rejectionDialog)}
              variant="contained"
              color="primary"
            >
              Reject KYC
            </Button>
          )}
          <Button onClick={onCancelHandler} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={onSaveHandler}
            variant="contained"
            color="primary"
            disabled={!required}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <RejectKyc
        rejectionDialog={rejectionDialog}
        setRejectionDialog={setRejectionDialog}
        rejectionReason={rejectionReason}
        setRejectionReason={setRejectionReason}
        onRejectHandler={onRejectHandler}
      />
    </>
  );
};

const FileDisplay = (props) => {
  const { classes, name, removeFile, url } = props;
  return (
    <>
      <Grid
        container
        md={6}
        xs={12}
        marginTop={2}
        classes={{ root: classes.docBox }}
        style={{ display: "flex", justifyContent: "space-around" }}
      >
        <Grid
          item
          xs={4}
          md={2}
          container
          alignItems="center"
          style={{ display: "flex" }}
        ></Grid>
        <Grid
          xs={8}
          md={8}
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid item md={12} style={{ display: "flex", width: "100%" }}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ marginRight: 4, width: "100%" }}
            >
              <Box>
                <Grid
                  item
                  md={12}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                  }}
                  classes={{
                    root: classes.textFieldRoot,
                  }}
                  onClick={() => {
                    window.open(url);
                  }}
                >
                  <Typography required variant="h5">
                    {name.length > 20
                      ? name.substring(0, 20) + "..."
                      : `${name}`}
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    variant="filled"
                    onClick={() => removeFile(name || "")}
                  >
                    <Clear />
                  </IconButton>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const styles = (theme) => ({
  textFieldRoot: {
    backgroundColor: "rgb(240,240,240)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    padding: "4px 12px",
    width: "100%",
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "8px",
    fontWeight: 500,
  },
  box: {
    padding: "10px",
  },
  docBox: {
    marginTop: "8px",
    marginBottom: "4px",
  },
});

export default withStyles(styles)(ApplyBvbp);
