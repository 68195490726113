import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import axios from "axios";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import {
  NavigateNext,
  NavigateBefore,
  Clear,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";

import AssistantsTable from "./AssistantsTable";
import ViewAssistants from "./ViewAssistants";
import { TablePagination } from "@material-ui/core";

import { TENANT_ID, URL } from "../../config/config";

class AssistantsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 15,
      value: 0,
      totalCount: null,
      skip: 0,
      timeStamp: new Date(),
      search: "",
      templates: [],
      err: null,
      isLoading: false,
      page: 0,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
        planActivatedOn: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
    };
  }
  componentDidMount() {
    this.fetchTemplates();
  }
  fetchTemplates = async () => {
    try {
      this.setState({ isLoading: true, err: null });
      const t = this.state;

      const { partner, bvbp } = this.props;
      let filter = {};
      if (this.props.partnerPage && t.search) {
        // search from partner page
        filter["$text"] = { $search: t.search, partnerId: partner._id };
      } else if (this.props.partnerPage && partner) {
        // on page visit
        filter["$text"] = { $search: partner._id };
      } else if (bvbp) {
        if (t.search) {
          filter["$text"] = { $search: t.search };
        }
        filter["bvbpProcess"] = this.state.value + 1;
      } else if (t.search) {
        // on assistant page
        filter["$text"] = { $search: t.search };
      }

      if (t.applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: t.applied.createdAt.startDate,
          $lte: t.applied.createdAt.endDate,
        };
      }
      if (t.applied.planActivatedOn.startDate) {
        filter["planActivatedOn"] = {
          $gte: t.applied.planActivatedOn.startDate,
          $lte: t.applied.planActivatedOn.endDate,
        };
      }
      const queryObj = {
        skip: t.skip,
        timeStamp: t.timeStamp,
        rowsPerPage: t.rowsPerPage,
        search: "",
        allFilters: { ...filter },
      };
      console.log(queryObj.allFilters);
      axios
        .post(URL + "/superadmin/get-assistants", { ...queryObj })
        .then((response) => {
          const { totalCount, newSkip, templates } = response.data;
          console.log(templates);
          this.setState({
            skip: newSkip,
            templates: [...this.state.templates, ...templates],
            totalCount,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ err, isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };

  getPageRows = () => {
    const { templates, page, rowsPerPage } = this.state;
    return templates.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };
  handleChangeTab = (event, newValue) => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        templates: [],
        isLoading: true,
        err: null,
        value: newValue,
      },
      () => {
        this.fetchTemplates();
      }
    );
  };
  handleChangePage = (event, newPage) => {
    const { templates, page, rowsPerPage } = this.state;
    const step = newPage - page;
    if (step === 1) {
      const pageRows = templates.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );
      if (!pageRows.length) {
        this.fetchTemplates();
      }
    }
    this.setState({ page: newPage });
  };

  setTemplateData = (id, key, data) => {
    const templates = [...this.state.templates];
    let updatedTemplates;
    if (key) {
      updatedTemplates = templates.map((template) => {
        if (template._id === id) {
          return { ...template, [key]: data };
        }
        return template;
      });
    } else {
      updatedTemplates = templates.filter((template) => template._id != id);
    }
    this.setState({
      templates: updatedTemplates,
    });
  };

  setTemplate = (template) => {
    const templates = [...this.state.templates];
    const foundIndex = templates.findIndex((x) => x._id == template._id);
    // console.log({foundIndex})
    // if(foundIndex > -1) {
    templates[foundIndex] = template;
    this.setState({ templates }, () => {
      console.log(this.state.templates);
    });
    // } else {
    // }
    // templates.forEach
  };
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key == "Enter") {
      this.setState(
        {
          timeStamp: new Date(),
          skip: 0,
          page: 0,
          totalCount: null,
          templates: [],
          isLoading: true,
          err: null,
        },
        () => {
          this.fetchTemplates();
        }
      );
    }
  };
  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        templates: [],
        isLoading: true,
        err: null,
      },
      () => {
        this.fetchTemplates();
      }
    );
  };
  render() {
    const { classes, partnerPage, bvbp } = this.props;
    return (
      <div className={partnerPage ? classes.partnerPageRoot : classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          alignItems="center"
          className={
            partnerPage
              ? `${classes.pageTitleContainer} ${classes.partnerPagefullWidth}`
              : `${classes.pageTitleContainer} ${classes.fullWidth}`
          }
          spacing={2}
          style={partnerPage ? { marginBottom: "10px" } : {}}
        >
          <Grid item>
            <Box pl={{ xs: 2, md: 3 }}>
              <Typography variant="h3">Assistants</Typography>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              name="search"
              placeholder="Search assistants"
              className={classes.textField}
              onChange={this.handleInput}
              onKeyPress={this.handleEnter}
              style={partnerPage ? { background: "white" } : {}}
            />
          </Grid>

          {!bvbp && (
            <>
              {" "}
              {!partnerPage ? (
                <Grid item>
                  <DateFilter
                    filterKey="planActivatedOn"
                    filterName="Plan Activated"
                    classes={classes}
                    applied={this.state.applied}
                    setDate={this.setDate}
                    setFocus={this.setFocus}
                    checkDateInput={this.checkDateInput}
                    clearDate={this.clearDate}
                  />
                </Grid>
              ) : (
                <></>
              )}
              <Grid item>
                <DateFilter
                  filterKey="createdAt"
                  filterName="Created At"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.applyFilter}
                >
                  Apply
                </Button>
              </Grid>
            </>
          )}
        </Grid>
        <Box my={3} />
        {/* Layout */}
        <Grid container justify="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              {bvbp && (
                <Tabs
                  value={this.state.value}
                  onChange={this.handleChangeTab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                >
                  <Tab label="KYC done" />
                  <Tab label="Applied" />
                  <Tab label="Limit Increased" />
                </Tabs>
              )}
              <AssistantsTable
                templates={this.getPageRows()}
                isLoading={this.state.isLoading}
                setTemplate={this.setTemplate}
                partnerPage={partnerPage}
                bvbp={bvbp}
                setTemplateData={this.setTemplateData}
                value={this.state.value}
                alertHandler={this.props.alertHandler}
              />
            </div>
          </Grid>
        </Grid>
        {/* Table footer fixed at bottom */}
        <div
          className={
            partnerPage
              ? classes.bottomContainerTenant
              : classes.fixedBottomContainer
          }
        >
          <TablePagination
            component="div"
            count={this.state.totalCount || 0}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={[25]}
          />
        </div>
      </div>
    );
  }
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={2} mb={2} className={classes.filterTypeContainer}>
      <Grid container justify="space-between" alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Typography variant="body1" style={{ marginRight: "10px" }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  partnerPageRoot: {
    width: "1070px",
    height: "100%",
    background: "#ebf5f3",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    borderRadius: "8px",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  partnerPagefullWidth: {
    width: "100%",
    background: "#ebf5f3",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  bottomContainerTenant: {
    marginTop: "5px",
    height: "50px",
    background: "#ebf5f3",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 70px - 60px - 40px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textField: {
    width: 300,
  },
});

export default withStyles(styles)(AssistantsPage);
