import React, { useState } from "react";
import {
  Dialog,
  Typography,
  Button,
  Grid,
  Box,
  Switch,
  DialogContent,
  FormControl,
  TextField,
  DialogTitle,
  FormHelperText,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { ADDONS } from "../../../config/addonMetadata";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";

function EnableAddonDialog(props) {
    const { open, onClose, addonKey, addon, activateAddon, modifyAddon } = props;
  const [topics, setTopics] = useState(
    addonKey === "WEBHOOKS" ? addon?.topics || ADDONS.WEBHOOKS.topics : null
  );
  const [aisensyPrice, setAisensyPrice] = useState(
    addon?.aisensyPrice || ADDONS[addonKey].aisensyPrice
  );
  const [aisensyPlans, setAisensyPlans] = useState(
    addon?.aisensyPlans || ADDONS[addonKey].aisensyPlans
  );
  const [dashboardKey, setDashboardKey] = useState(
    addon?.dashboardKey || false
  );
  const [startDate, setStartDate] = useState(null);

  const DatePicker = () => {
    return (
      <Grid style={{ paddingBottom: "16px" }}>
        <Typography variant="subtitle1" gutterBottom>
          Addon Start Date
        </Typography>
        <FormControl fullWidth>
          <FormHelperText>
            The date from which this addon will be activated
          </FormHelperText>
          <TextField
            defaultValue={(startDate || moment()).format("YYYY-MM-DD")}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => {
              setStartDate(moment(e.target.value));
            }}
          />
        </FormControl>
      </Grid>
    );
  };
  const AisensyAnnualPricing = () => {
    return (
      <Box paddingBottom="16px">
        <Typography variant="subtitle1" gutterBottom>
          AiSensy Annual Pricing
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Typography variant="body1" gutterBottom>
                INR Pricing
              </Typography>
              <FormHelperText>Enter the addon amount in INR</FormHelperText>
              <TextField
                defaultValue={aisensyPrice.INR / 100000}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₹</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  aisensyPrice.INR = Math.round(
                    Number(e.target.value) * 100000
                  );
                  setAisensyPrice(aisensyPrice);
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Typography variant="body1" gutterBottom>
                USD Pricing
              </Typography>
              <FormHelperText>Enter the addon amount in USD</FormHelperText>
              <TextField
                defaultValue={aisensyPrice.USD / 100000}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                onChange={(e) => {
                  aisensyPrice.USD = Math.round(
                    Number(e.target.value) * 100000
                  );
                  setAisensyPrice(aisensyPrice);
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const CustomFields = () => {
    switch (addonKey) {
      case "WEBHOOKS":
        return (
          <>
            <Grid
              container
              style={{
                alignItems: "center",
              }}
            >
              <Grid item xs={12} style={{ marginBottom: "16px" }}>
                <Typography variant="body1" gutterBottom>
                  Topics
                </Typography>
                <FormHelperText>
                  Select the topics you want to subscribe
                </FormHelperText>
                <Autocomplete
                  multiple
                  options={ADDONS.WEBHOOKS.topics}
                  disablePortal
                  filterSelectedOptions
                  defaultValue={[...topics]}
                  onChange={(e, value, reason) => {
                    setTopics(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="topics"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        );
      case "META_ADS":
        return (
          <>
            <Grid
              container
              alignItems="center"
              style={{ paddingBottom: "16px" }}
            >
              <Grid item xs={6}>
                Dashboard Key
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Switch
                  checked={dashboardKey}
                  onChange={(e) => setDashboardKey(e.target.checked)}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Grid>
            </Grid>
          </>
        );
      case "FLOW_BUILDER":
        return (
          <>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ paddingBottom: "16px" }}
            >
              First 5 Flow Charge
            </Typography>
            <Grid container spacing={2}>
              {Object.keys(aisensyPlans.tier1.plans).map((plan) => {
                return (
                  <>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ paddingTop: "8px" }}
                        >
                          {plan} PLAN(INR)
                        </Typography>
                        <FormHelperText>Enter the amount in INR</FormHelperText>
                        <TextField
                          defaultValue={
                            aisensyPlans.tier1.plans[plan].INR / 100000
                          }
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                ₹
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            aisensyPlans.tier1.plans[plan].INR = Math.round(
                              Number(e.target.value) * 100000
                            );
                            setAisensyPlans(aisensyPlans);
                          }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth>
                        <Typography
                          variant="body1"
                          gutterBottom
                          style={{ paddingTop: "8px" }}
                        >
                          {plan} PLAN(USD)
                        </Typography>
                        <FormHelperText>Enter the amount in USD</FormHelperText>
                        <TextField
                          defaultValue={
                            aisensyPlans.tier1.plans[plan].USD / 100000
                          }
                          type="number"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                $
                              </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            aisensyPlans.tier1.plans[plan].USD = Math.round(
                              Number(e.target.value) * 100000
                            );
                            setAisensyPlans(aisensyPlans);
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </>
                );
              })}
            </Grid>
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{ paddingTop: "16px", paddingBottom: "16px" }}
            >
              Subsequent 10 Flow Charge
            </Typography>
            {Object.keys(aisensyPlans.tier2.plans).map((plan) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ paddingTop: "8px" }}
                      >
                        {plan} PLAN(INR)
                      </Typography>
                      <FormHelperText>Enter the amount in INR</FormHelperText>
                      <TextField
                        defaultValue={
                          aisensyPlans.tier2.plans[plan].INR / 100000
                        }
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">₹</InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          aisensyPlans.tier2.plans[plan].INR = Math.round(
                            Number(e.target.value) * 100000
                          );
                          setAisensyPlans(aisensyPlans);
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Typography
                        variant="body1"
                        gutterBottom
                        style={{ paddingTop: "8px" }}
                      >
                        {plan} PLAN(USD)
                      </Typography>
                      <FormHelperText>Enter the amount in USD</FormHelperText>
                      <TextField
                        defaultValue={
                          aisensyPlans.tier2.plans[plan].USD / 100000
                        }
                        type="number"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          aisensyPlans.tier2.plans[plan].USD = Math.round(
                            Number(e.target.value) * 100000
                          );
                          setAisensyPlans(aisensyPlans);
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              );
            })}
          </>
        );
      case "SSO":
        return <></>;
      case "SHOPIFY":
        return <></>;
      case "LEADSQUARED":
        return <></>;
      case "CLEVERTAP":
        return <></>;
      case "MOENGAGE":
        return <></>;
      case "WEBENGAGE":
        return <></>;
      case "WOOCOMMERCE":
        return <></>;
      case "PABBLY":
        return <></>;
      case "RAZORPAY":
        return <></>;
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{ zIndex: 3002 }}
      disableEnforceFocus
      fullWidth
    >
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Typography variant="h3" component="p">
              {!addon ? "Enable Addon" : "Modifiy Addon"}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {!addon && <DatePicker />}
        <AisensyAnnualPricing />
        <CustomFields />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          style={{ marginTop: "16px", marginBottom: "24px" }}
          onClick={() => {
            if (!addon)
              activateAddon(
                addonKey,
                aisensyPrice,
                aisensyPlans,
                topics,
                dashboardKey,
                startDate
              );
            else
              modifyAddon(
                addonKey,
                aisensyPrice,
                aisensyPlans,
                topics,
                dashboardKey
              );
            onClose();
          }}
        >
          {!addon ? "Enable Addon" : "Modify Addon"}
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default EnableAddonDialog;
