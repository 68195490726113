import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";

const RejectKyc = (props) => {
  const {
    classes,
    rejectionDialog,
    setRejectionDialog,
    rejectionReason,
    setRejectionReason,
    onRejectHandler,
  } = props;
  return (
    <>
      <Dialog
        onClose={() => setRejectionDialog(!rejectionDialog)}
        open={rejectionDialog}
        // closeDialog={toggleBvbpDialog}
        maxWidth={"md"}
      >
        <DialogTitle>
          Are you sure you want to reject the submitted Kyc
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item style={{ width: "100%" }}>
              <TextField
                className={classes.textFieldRoot}
                value={rejectionReason}
                placeholder="Enter rejection reason"
                onChange={(e) => setRejectionReason(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => setRejectionDialog(!rejectionDialog)}
                color="primary"
                variant="outlined"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={onRejectHandler}
                variant="contained"
                color="primary"
                disabled={!rejectionReason}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  textFieldRoot: {
    backgroundColor: "rgb(240,240,240)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    padding: "4px 12px",
    width: "100%",
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "8px",
    fontWeight: 500,
  },
});

export default withStyles(styles)(RejectKyc);
