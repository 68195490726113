export const ADDONS = {
  WEBHOOKS: {
    name: "Webhooks",
    topics: [
      "message.created",
      "message.status.updated",
      "message.sender.user",
      "contact.created",
      "contact.attribute.updated",
      "contact.chat.intervened",
      "contact.chat.requesting",
      "contact.chat.closed",
      "contact.campaign.sent",
      "contact.campaign.read",
      "contact.first_message.added",
      "contact.first_message.removed",
      "contact.tag.added",
      "contact.tag.removed",
      "payment.captured",
      "payment.refunded",
      "order.placed",
      "message_status",
      "incoming_message",
      "message_template_status_update",
      "phone_number_quality_update",
      "phone_number_name_update",
      "account_alerts",
      "account_review_update",
      "account_update",
      "business_capability_update",
      "message_template_quality_update",
      "security",
      "template_category_update",
      "campaign_status_update",
      "business_status_update",
      "message.clicked",
      "message.replied",
    ],
    aisensyPrice: {
      INR: 1000000000,
      USD: 20000000,
    },
  },
  META_ADS: {
    name: "Meta Ads",
    dashboardKey: false,
    aisensyPrice: {
      INR: 1500000000,
      USD: 25000000,
    },
  },
  FLOW_BUILDER: {
    name: "Flow Builder",
    flowCount: 5,
    aisensyPrice: {
      INR: 2000000000,
      USD: 40000000,
    },
    aisensyPlans: {
      tier1: {
        minFlows: 5,
        plans: {
          MONTHLY: {
            name: "Basic Flows Monthly Plan",
            INR: 150000000,
            USD: 3200000,
          },
          QUARTERLY: {
            name: "Basic Flows Quarterly Plan",
            INR: 427500000,
            USD: 9120000,
          },
          YEARLY: {
            name: "Basic Flows Yearly Plan",
            INR: 1620000000,
            USD: 34560000,
          },
        },
      },
      tier2: {
        minFlows: 10,
        plans: {
          MONTHLY: {
            name: "Additional Flows Monthly Plan",
            INR: 150000000,
            USD: 3200000,
          },
          QUARTERLY: {
            name: "Additional Flows Quarterly Plan",
            INR: 427500000,
            USD: 9120000,
          },
          YEARLY: {
            name: "Additional Flows Monthly Plan",
            INR: 1620000000,
            USD: 34560000,
          },
        },
      },
    },
  },
  SSO: {
    name: "Single Sign On",
    aisensyPrice: {
      INR: 500000000,
      USD: 10000000,
    },
  },
  SHOPIFY: {
    name: "Shopify Integration",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  LEADSQUARED: {
    name: "LeadSquared Integration",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  CLEVERTAP: {
    name: "CleverTap Integration",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  MOENGAGE: {
    name: "MoEngage Integration",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  WEBENGAGE: {
    name: "WebEngage Integration",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  WOOCOMMERCE: {
    name: "Woocommerce Integration",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  PABBLY: {
    name: "Pabbly",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
  RAZORPAY: {
    name: "Razorpay",
    aisensyPrice: {
      INR: 1500000000,
      USD: 2500000,
    },
  },
};
